:root {
    /*Margins & Paddings*/
    --padding: 20px;
    --margin: 1em;
    --vertical-padding: 20px; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --header-min-height: 80px;
    --fixed-header-height: 70px; /*mobile menu*/
    /*Fonts*/
    --base-font-family: 'Lato', sans-serif;
    --alternative-font-family: 'Lato', sans-serif;
    --base-font-weight: 300;
    --alternative-font-weight: 700;
    /*Colors*/
    --base-font-color: #252525;
    --alternative-font-color: #ff7c1b;
    --map-marker-color: 000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
/*@import "/theme/intendit/css/modules/flex-grid.css";   */
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/theme/intendit/css/modules/menu.css";   
@import "/css/magnific-popup.css";
@import "/theme/intendit/css/modules/hoverboxes.css";   
@import "/fonts/Advent Pro/all.css";  
@import "/fonts/Lato/all.css";  
@import "/css/animate.css";
/*
@import "/css/pikaday.css";

*/
 
.loggain.loggain {background:#ff7c1b; color:#fff; margin-right:10px;  }
 .loggain.loggain a {  color:#fff; text-transform:uppercase; }
.index-5.index-5 {border:none !important; }
.branch h3 { text-align: center; color:#606060; padding: 0 0 20px;  }

.branch {background:#fff; padding: 30px 0 20px;   }
.logotype-gallery.center {flex-direction: row; }
/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {color: var(--base-font-color); font-family: var(--base-font-family); font-weight: var(--base-font-weight); line-height:1.2; 
    border-top: var(--fixed-header-height) solid transparent;
/*background: url('/thumbs/1920x1080r/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/ }

.flex.margin > * {margin-top:0; }

/*wrappers*/
main.wrapper {min-height: calc(100vh - var(--header-min-height)); position:relative; }  
.homepage main.wrapper {min-height: 10px;}  

.content-wrapper {padding: 40px 0 50px; position:relative; /*box-shadow: 0 0 50px 30px rgba(0, 0, 0, 0.2);*/} 
.container {width:100%; }
.container.xs {max-width:var(--breakpoint-xs);}
.container.sml {max-width:var(--breakpoint-s);}
.container.lrg {max-width: 1300px;}

/*basic styles*/
a, a:hover {color:var(--base-font-color); font-weight: var(--base-font-weight); text-decoration: none;}
p {margin:0.2em 0 1.2em;}
.content-wrapper p a {color:var(--alternative-font-color); font-weight:400; }
.content-wrapper p a:hover {text-decoration: underline;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------
.content-wrapper ul {list-style:none; padding: 0; margin: 0.2em 0 1em;}
.content-wrapper ul li:before {content: "-"; margin:0 0.5em 0 0; font-size:1.1em; font-weight:bold; }*/
/*.content-wrapper ul li {width: calc(50% - 2px); margin:0 2px 2px 0; float:left;}*/
/*.content-wrapper ul li:before {content: "\2714"; margin: 0 0.2em 0 0; font-size:1.2em; }*/

/*table*/
table {font-size:95%; width:100%;}
table tr {border-bottom: 1px solid #e4e4e4;}
table tr td {padding:5px 20px 5px 0; vertical-align: top; border:none; } 
.table-wrapper {display:table; width:100%; }  
.table-wrapper .fa-icon { height: 25px; width:100px; display:block; margin: 0 auto; background:#d0d0d0; fill:#fff; border-radius:40px;  }
.table-wrapper h3 {width:100%; margin-top: 10px; }

/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
/*.breadcrumbs {padding-bottom: 1em; font-size:0.9em; color:#606060; }
.breadcrumbs a { color:#606060; }*/

 
/*------------------------------------------------------------------------------------------------ 
IMAGE VIDEO FILELIST-----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}
/*artikelbild*/
figure.page-image { }
figure.page-image img {display:block; width:100%; height:auto; /*border-radius:50%; */  }
figure.page-image figcaption {font-style:italic; color:#606060;}  


.sidospalt {float:right; max-width:40%; height:auto; margin: 0 0 2em 2em; padding: 20px; background: #e4e4e4; }
/*filelist*/ 
a.file {display:block; }

/*------------------------------------------------------------------------------------------------ 
BUTTONS & ICONS --------------------------------------------------------------------------------*/
.button.button, .readmore {margin:0; padding: 6px 0 8px; display:block; width:100%; text-align: center; border:none; border-radius:2px; 
background:#ff7c1b; font-weight: 400; color:#fff;    }
.readmore {max-width:100px; margin: 10px auto; border-radius: 40px;  } 
.button.button:hover, .readmore:hover {color:#fff;font-weight: 400;  }
/*ikoner*/
.fa-icon {height:20px; width: 25px; vertical-align:middle !important; }
.round {background: rgba(0, 0, 0, 0.5); border-radius: 50%; display: inline-block; height: 40px; width: 40px; margin: 0 10px 10px 0; }
.round .fa-icon {fill:#fff; padding: 8px 0; height:40px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

footer .round {background: rgba(255, 255, 255, 0.2);}
footer svg {fill:#fff; }
.toptext .round {display:block; }
 .boxikon .fa-icon {  height:50px; width: 50px; fill:#ff7c1b; display:block; margin: 0 auto 10px;  }
 
/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding: 0; margin: 0; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); }
h1{font-size: 2em; margin: 0 0 0.5em; /*text-transform:uppercase;*/}
h1.twelve {max-width:100%; }
h2{font-size: 1.65em;}
h3{font-size: 1.3em;}  
h4, h5, h6 {font-size: 1.3em; } 

h2.highlight {font-size:1.5em; padding: 0 0 0.5em; font-weight:700; color: var(--alternative-font-color); }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:10px; top:90px; z-index:11; display:none; } /*display none by default*/
.imagetop {min-height:50vh; background-position: center center; background-size:cover; position: relative; border-bottom: 10px solid #e4e4e4; }

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/
/*slick slider*/
.slick-wrapper {min-height:calc(100vh - var(--fixed-header-height)); flex-wrap: wrap; position:fixed; width:100%; top: var(--fixed-header-height); z-index:-1;  }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:calc(100vh - var(--fixed-header-height)); margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { top:0; left:0; bottom:0; right:0; width:100%; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center center; display:flex !important;}   
.slick {visibility: hidden;}  .slick-initialized {visibility: visible;}  .slick-list, .slick-track {height:100%;}

 
/*Starttext - aktivera nedersta för starttext inuti slick-wrapper*/ 
.starttext {opacity:1 ; transition:opacity 0.5s ease-in 0s; padding: 120px 0 30px; width:100%;  text-align:center; z-index: 0; position:relative; color: #fff; text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
.starttext h1 {
    color: #ff7c1b;
    font-size: 4.2em;
    letter-spacing: 2pt;
    margin: 0 0 5px;
}
.starttext p {
    font-size: 2.4em;
    font-weight: 400;
    line-height: 1.1;
}
.jourlink {
    background: rgba(255,124, 27, 0.8) none repeat scroll 0 0;
    color: #fff;
    display: block;
    clear:both; 
    width: 100%; max-width: 450px; 
    font-size: 2em;
    font-weight: 900;
    padding: 10px 20px;
    margin: 0 auto 10px;
}

 .slick-slider, .slick-substitute > div { position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; } 
 

/*----------------------Boxar-------------------------------*/
.boxes {padding: 4em 0; position:relative; background:rgba(0, 0, 0, 0.5); color:#fff; margin-top: 100vh;  transition:background 0.5s ease-in 0s;  }
.boxes > div > div {text-align: center; }
.boxes h2 {font-size:1.5em; }

body.scrolled .boxes {  background:rgba(0, 0, 0, 0.9); transition:background 0.5s ease-in 0s;}
body.scrolled .starttext {  opacity:0; transition:opacity 0.5s ease-in 0s;}
/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/
 
/*gallery*/
.image-gallery  {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
 .gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px; display:none; }  

/*logo gallery*/
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:50%; } .landscape  {width:85%; }.longlandscape {width:90%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Advent Pro', sans-serif; font-weight:100; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
/* .mfp-title a, .mfp-title a:hover {color:#fff; }
.mfp-title .fa-icon {height:16px; vertical-align: bottom !important;}*/



/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
 
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060; margin:0;  } 
.boltform .flex.margin > * {margin: 0 calc(0.5 * var(--margin)) calc(0.5 * var(--vertical-margin)); }
.contact-form .boltforms-row label, .contact-form .boltforms-row button, .contact-form .boltforms-row button:hover { color:#fff; font-weight: var(--base-font-weight);   margin-bottom: 0.3em;   }
.contact-form .button.button {margin: 10px 0 0; background: rgba(0, 0, 0, 0.2);}
/*karta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{ background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
background-size: cover; padding: 10px; background-position: center; }

/*footer*/
footer {padding: 50px 0 120px; width:100%; background:#ff881e; position:relative; }
footer, footer a, footer a:hover {color:#fff; }
footer h4 { padding: 0 0 5px; font-size:1.15em; } 
/*sticky*/
html.sticky {height:100%;} .sticky body {display:flex; flex-direction:column; min-height:100%;}
.sticky main.wrapper {flex: 1 0 auto; min-height:1em; } .sticky header, .sticky footer {flex: 0 0 auto; }

/*intendit*/
.created-by {text-align: center; padding: 8px 0; background:#101010; position:relative;}
.created-by p, .created-by a {color:#707070; font-size: 0.9em; letter-spacing: 0.5pt; margin: 0;}
 

 
/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header.flex {background:#fff;position:fixed;  width:100%; top:0;  z-index:10; min-height:var(--fixed-header-height); font-family: var(--base-font-family);}
header > div {min-height:inherit;} 
body.scrolled header {/*transition:background 0.5s ease-in 0s;*/}

/*Logotype*/
.logo { padding:10px 15px; box-sizing:border-box; max-width: calc(100% - 60px); overflow:hidden; } 
.logo a, .logo a:hover {display:block; width: 100%; } 
.logo a img {max-height: 60px; width:auto; display:block; max-width:100%;  }
.logo a span {display:block; max-width:100%; font-size:2em; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); line-height: 1em;}

/*Menu*/
ul.menu > li:not(.last) { border-right: 1px solid #d0d0d0; }
ul.menu li:hover a, ul.menu li.active a, ul.menu li.activeparent a {/*transition:color 0.5s ease-in 0s;*/}
ul.menu li.dropdown > a:after { content: ">"; transform: rotate(90deg);  display: inline-block; font-family: 'Advent Pro', sans-serif; font-weight:200; margin: 0 0 0 5px; }
 
/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){ 
header.flex { position:fixed; min-height:var(--fixed-header-height); box-shadow:none; flex-wrap:nowrap;} 
body { border-top: var(--fixed-header-height) solid transparent;}     
.logo { padding: 15px 10px 5px 10px; align-items: flex-start; }
nav ul.menu.show { background:rgba(255, 255, 255, 0.9);}   
ul.menu.menu li  { border:none; background:none;  }
nav ul.menu li.dropdown > a:after {display:none; }
nav #menu-icon {align-self: flex-start; padding: 20px 15px 20px 0;}
nav #menu-icon span, nav #menu-icon:before, nav #menu-icon:after { border-radius:3px; color:#000;}
    
    .loggain.loggain a{ color:#404040 ; font-weight:400;}
     
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
  .starttext { 
      padding: 50px 0;  }
.starttext h1 { 
    font-size: 3.6em; }
    .starttext p {
        font-size: 2em;}
    .starttext h2 { 
    font-size: 2.4em;
     padding: 10px 30px;
}
    
/* Standard meny - tight it up
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}   */ 
    
/*Container*/
.container.lrg {width: 96%;}   
header.container.lrg {width: 100%;}   
    
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*flex*/    
.flex.m-no-lr-margin > * {margin-left:0 !important; margin-right:0 !important; }
.flex.m-no-padding > * {padding:0 !important;}
   
    
/*Startsida*/ 
.starttext {}
.starttext h1 {}
.starttext p {} 
    
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
    
/*slick arrows*/   
.slick-prev, .slick-next {height: 60px; width: 60px;}
.slick-prev:before, .slick-next:before { font-size: 60px;}   
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    body.scrolled .starttext {opacity:1; }
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size:2.8em;}
.starttext p { font-size: 1.6em;} 
    .starttext h2 {
    font-size: 2.2em;
    padding: 10px 30px;
}
    .boxes {background:#000; border-top: 10px solid #fff; } 
/*Fontsize*/ 
body {font-size: 0.95em; }
    
/*Slider & topbild height */ 
.slick-wrapper {position:relative; top: auto; }
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {min-height:65vw;}
.imagetop {min-height:45vw;}
 
.slick-wrapper + .starttext {margin-top:0;}
.boxes {margin-top:0;}    
    
/*gallerithumbs*/
.gallery-image.gallery-image {box-shadow: none;}
.gallery-image img {padding: 0.8em;}    
 
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
     
/*Footer & Contact*/   
footer .s-twelve {  margin: 0 auto !important; max-width: 400px; padding: 20px 0 10px; text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
    
/*Not found*/
.pagenotfound li a {padding: 10px 20px; width: 100%;}
 
/*Containers*/
.container.sml {width: 96%;} 
    
/*flex margin & paddings*/    
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve  {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}    
.flex.s-no-padding > * {padding:0 !important;}
 
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{   
/*font*/    
h1 {font-size:2em;} 

/*main*/
main.wrapper {min-height: 10px; }  
.content-wrapper {padding: 30px 0;}
    
/*imagetop*/  
.imagetop {min-height:50vw;}
    
    
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size:2.4em; letter-spacing:0 ; }
.starttext p {} 
.starttext p { font-size: 1.2em;} 
    .starttext h2 {   line-height: 1.7em;
    font-size: 1.8em;
    padding: 7px 20px;
}    
/*image*/
figure.page-image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 40px; }     
     
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.contact-banner {padding: 30px 0;}

/*map*/
.map-canvas {width:100%; max-height:20vh;} 
 
    
/*Containers*/
.container.xs {width: 96%;}  
    
/*flex margin & paddings*/ 
.margin > .xs-twelve {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}
.flex.xs-no-padding > * {padding:0 !important;} 

/*logo*/
.logo a span {padding:10px; font-size: 1.7em;}  
.logo a span {overflow-wrap: break-word; word-wrap: break-word;word-break: break-all;word-break: break-word; hyphens: auto;} 
.logo {padding:0; }    .logo a img {padding:15px 10px 5px 10px; max-height: 80px ;}   
 
a[href^="mailto"] .fa-icon {height: 18px; vertical-align: sub !important;}
   
}

@media (max-width: 375px)
{    
 h1, h2, h3, h4, h5, h6 { hyphens: auto;}
  
/*font*/    
h1 {font-size:1.8em !important; }  

/*start*/
     
    .starttext h2 { 
    font-size: 1.6em;
 }    
    
    
/*bild*/
figure.page-image { width:100%; max-width:100%; margin: 0 auto 1em; float:none; }     
}

 
/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
